import React, {useEffect, useState} from 'react'
import kakaoInquriyWide from '@Image/Api/kakao_chat_wide.png'
import kakaoInquriy from '@Image/Api/kakao_chat.png'

import {Link} from 'react-router-dom'

import {getUserInfo, userLogoutApi} from '@Api/user/account'
import {UserDto, UserRole} from "@Types/user";

import {getModalInstance} from "@Component/common/Modal/ModalProvider";

import {openProductSearch} from "@Component/domains/product/Search/ProductSearch";
import {getCategoryListApi} from "@Api/cmm";
import styles from './index.module.scss';
import LinkBuilder from "@Component/common/LinkBuilder";
import {adminBoardLinks, adminLinks, adminMainLink, boardLinks, mainLink, orderLinks, shopLinks} from './linkType'
import {CategoryList} from "@Types/category/categoryDto";
import ImageTag from '@Component/common/Image/ImageTag'

const logoAlt: string = 'brospo Main logo'


export const Header = (props: { isAdmin: boolean }) => {
    const [categoryList, setCategoryList] = useState<CategoryList>({})
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
    const [isHovered, setIsHovered] = useState(false) // hover 상태를 저장하는 상태 변수

    const currentHref = window.location.href
    const modal = getModalInstance()
    const prefix = (props.isAdmin ? '/admin' : '');

    const logo = process.env.LOGO_IMAGE_BASE_URL! + 'logo.png';
    const fetchData = async () => {
        setUserInfo(await getUserInfo())
        setCategoryList(await getCategoryListApi());
    }

    const CategoryLink = (props: { mobile: boolean, isAdmin: boolean }) => {

        return <div className={styles.category}>
            {props.isAdmin && !props.mobile && (<div>
                <LinkBuilder linkMap={adminLinks} onClickEvent={closeMobileSubMenu}/>
                {userInfo?.role == UserRole.SYS && <Link to={'/admin/swagger'} onClick={closeMobileSubMenu}>API 명세서</Link>}
            </div>)}
            {Object.keys(categoryList).map(
                (primaryCategory) => (
                    <div key={primaryCategory}>
                        {props.mobile ? (<p>
                            <Link
                                to={
                                    prefix + '/product/list/' +
                                    primaryCategory
                                }
                                id="primary"
                                onClick={closeMobileSubMenu}

                            >
                                {primaryCategory}
                            </Link>
                            <i
                                className="icon-down-open"
                                onClick={handleMobileClick}
                            ></i>
                        </p>) : (<Link
                            to={
                                prefix + '/product/list/' +
                                primaryCategory
                            }
                            id="primary"
                            onClick={() => setIsHovered(false)}
                        >
                            {primaryCategory}
                        </Link>)}

                        {/* 각 그룹의 secondaryCategory를 반복하여 출력 */}
                        {categoryList[primaryCategory].map(
                            (category, index) => (
                                <Link
                                    key={index}
                                    to={
                                        prefix + '/product/list/' +
                                        primaryCategory +
                                        '/' +
                                        category.secondaryName
                                    }
                                    onClick={() => {
                                        if (props.mobile) {
                                            closeMobileSubMenu()
                                        } else {
                                            setIsHovered(false)
                                        }
                                    }}
                                >
                                    {category.secondaryName}
                                </Link>
                            )
                        )}
                    </div>
                )
            )}
        </div>
    }

    const handleMobileClick = (event: React.MouseEvent<HTMLElement>) => {
        const icon = event.currentTarget as HTMLElement
        const parentDiv = icon.closest('div') as HTMLElement
        const maxHeight = parentDiv.scrollHeight
        const currentHeight = parentDiv.clientHeight

        const duration = 200 // 애니메이션 지속 시간 (밀리초)
        const interval = 10 // 애니메이션 갱신 간격 (밀리초)
        const frameCount = duration / interval // 총 프레임 수
        const heightIncrement = (maxHeight - 44) / frameCount // 프레임 당 높이 증가량

        let currentIteration = 0
        let tempHeight = currentHeight

        const increaseHeight = () => {
            const intervalId = setInterval(() => {
                tempHeight += heightIncrement
                parentDiv.style.height = `${tempHeight}px`

                currentIteration++
                if (currentIteration >= frameCount) {
                    clearInterval(intervalId)
                    parentDiv.style.height = `${maxHeight}px`
                }
            }, interval)
        }

        const decreaseHeight = () => {
            const intervalId = setInterval(() => {
                tempHeight -= heightIncrement
                parentDiv.style.height = `${tempHeight}px`

                currentIteration++
                if (currentIteration >= frameCount) {
                    clearInterval(intervalId)
                }
            }, interval)
        }

        if (maxHeight === currentHeight) {
            decreaseHeight()
            if (icon) {
                icon.style.transform = 'rotate(0deg)'
            }
        } else {
            increaseHeight()
            if (icon) {
                icon.style.transform = 'rotate(180deg)'
            }
        }
    }

    const openMobileSubMenu = () => {
        const mobileHeader = document.querySelector('.mobile-header')
        if (mobileHeader instanceof HTMLElement) {
            const subMenu = mobileHeader.querySelector('.sub-menu')
            if (subMenu instanceof HTMLElement) {
                subMenu.style.transform = 'translateX(0%)'
            }
        }

        const sideBar = document.querySelector('.mobile-side-bar')
        if (sideBar instanceof HTMLElement) {
            sideBar.style.transform = 'translateX(150%)'
        }

        // Body에 overflow: hidden; 속성 추가
        document.body.style.overflow = 'hidden'

        // .sub-menu-bg 요소 display 속성 변경
        ;(document.querySelector('.sub-menu-bg') as HTMLElement).style.display =
            'block'
    }

    const closeMobileSubMenu = () => {
        const mobileHeader = document.querySelector('.mobile-header')
        if (mobileHeader instanceof HTMLElement) {
            const subMenu = mobileHeader.querySelector('.sub-menu')
            if (subMenu instanceof HTMLElement) {
                subMenu.style.transform = 'translateX(-100%)'
            }
        }

        const sideBar = document.querySelector('.mobile-side-bar')
        if (sideBar instanceof HTMLElement) {
            sideBar.style.transform = 'translateX(0%)'
        }

        // Body에서 overflow: hidden; 속성 제거
        document.body.style.overflow = ''

        // .sub-menu-bg 요소 display 속성 변경
        ;(document.querySelector('.sub-menu-bg') as HTMLElement).style.display =
            'none'

        // 각 요소들의 높이 초기화
        const elements = document.querySelectorAll(
            'header .mobile-header .sub-menu > div > div'
        )
        elements.forEach((element) => {
            element.setAttribute('style', 'height: 44px')
        })
    }


    useEffect(() => {
        fetchData()
    }, [])


    // @ts-ignore
    return (
        <header className={styles.header}>
            <div className={styles.pcHeader}>
                <div className={styles.headerLogo}>
                    <Link to={''} onClick={() => setIsHovered(false)}>
                        <ImageTag src={logo} alt={logoAlt}/>
                    </Link>
                </div>
                <div
                    className={styles.headerMenu}
                    onMouseEnter={() => setIsHovered(true)}
                >
                    <LinkBuilder linkMap={props.isAdmin? adminMainLink : mainLink} onClickEvent={() => {
                        setIsHovered(true)
                    }}/>
                </div>
                {isHovered && (
                    <div
                        className={styles.subMenu}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <CategoryLink mobile={false} isAdmin={props.isAdmin}/>
                        <div className={styles.shop}>
                            {props.isAdmin ? (<>
                                <div className={styles.board}>
                                    <p>주문관리</p>
                                    <LinkBuilder linkMap={orderLinks} onClickEvent={() => {
                                        setIsHovered(true)
                                    }}/>
                                </div>
                                <div className={styles.board}>
                                    <p>게시판 관리</p>
                                    <LinkBuilder linkMap={adminBoardLinks} onClickEvent={() => {
                                        setIsHovered(true)
                                    }}/>
                                </div>
                            </>) : (<>
                                <div className={styles.board}>
                                    <p>커뮤니티</p>
                                    <LinkBuilder linkMap={boardLinks} onClickEvent={() => {
                                        setIsHovered(true)
                                    }}/>
                                    {userInfo && userInfo?.role != 'USER' &&
                                        <Link to={'/admin'} onClick={closeMobileSubMenu}>관리자 콘솔</Link>}
                                </div>
                                <div className={styles.shop}>
                                    <p>쇼핑몰 정보</p>
                                    <LinkBuilder linkMap={shopLinks} onClickEvent={() => {
                                        setIsHovered(true)
                                    }}/>
                                    <Link
                                        to={'http://pf.kakao.com/_iJGzxj/chat'}
                                        onClick={() => setIsHovered(false)}
                                        target={'_blank'}
                                    >
                                        <img src={kakaoInquriy} alt={logoAlt}/>
                                    </Link>
                                </div>
                            </>)}
                        </div>
                    </div>
                )}

                <div className={styles.headerIcon}>
                    {userInfo ? (
                        <>
                            <a className={styles.userName}>{userInfo?.userName + ' 님'}</a>
                            <Link to={'#'} onClick={userLogoutApi}>
                                로그아웃
                            </Link>
                            {props.isAdmin ? <Link
                                to={'/'}
                                onClick={closeMobileSubMenu}
                            >
                                <i className="icon-home"/>
                            </Link> : (
                                <Link
                                    to={'/mypage/account'}
                                    onClick={closeMobileSubMenu}
                                >
                                    <i className="icon-user-circle-o"/>
                                </Link>)}
                        </>
                    ) : (
                        <Link to={'/user/login'} id={'user-login'}>
                            로그인
                        </Link>
                    )}
                    <a onClick={openProductSearch}>
                        <i className="icon-search"/>
                    </a>
                </div>
            </div>
            <div className={styles.mobileHeader + ' mobile-header'}>
                <div className={styles.headerIcon} onClick={openMobileSubMenu}>
                    <i className="icon-menu"></i>
                </div>
                <div className={styles.headerLogo}>
                    <Link to={''}>
                        <img src={logo} alt={logoAlt}/>
                    </Link>
                </div>

                <div className={styles.headerIcon} onClick={openProductSearch}>
                    <i className="icon-search"/>
                </div>
                <div className={styles.subMenu + ' sub-menu'}>
                    <div className={styles.closeButton}>
                        <i
                            className="icon-cancel"
                            onClick={closeMobileSubMenu}
                        ></i>
                    </div>
                    <div>
                        <div className={styles.headerLogo}>
                            <Link to={''} onClick={closeMobileSubMenu}>
                                <img src={logo} alt={logoAlt}/>
                            </Link>
                        </div>
                        <div className={styles.headerUser}>
                            {userInfo ? (
                                <>
                                    <a className={styles.userName}>
                                        {userInfo?.userName + ' 님'}
                                    </a>
                                    <Link to={'#'} onClick={userLogoutApi}>
                                        로그아웃
                                    </Link>
                                    {props.isAdmin ? <Link
                                        to={'/'}
                                        onClick={closeMobileSubMenu}
                                    >
                                        <i className="icon-home"/>
                                    </Link> : (
                                        <Link
                                            to={'/mypage/account'}
                                            onClick={closeMobileSubMenu}
                                        >
                                            <i className="icon-user-circle-o"/>
                                        </Link>)}
                                </>
                            ) : (
                                <Link
                                    to={'/user/login'}
                                    id={'user-login'}
                                    onClick={closeMobileSubMenu}
                                >
                                    로그인
                                </Link>
                            )}
                        </div>
                    </div>

                    {props.isAdmin && (<div>
                        <p>상품/홈페이지 관리</p>
                        <LinkBuilder linkMap={adminLinks} onClickEvent={closeMobileSubMenu}/>
                        {userInfo?.role == UserRole.SYS &&
                            <Link to={'/admin/swagger'} onClick={closeMobileSubMenu}>API 명세서</Link>}
                    </div>)}

                    <CategoryLink mobile={true} isAdmin={props.isAdmin}/>

                    {props.isAdmin ?
                        (<>
                                <div>
                                    <p>게시판 관리</p>
                                    <LinkBuilder linkMap={adminBoardLinks} onClickEvent={closeMobileSubMenu}/>

                                </div>
                                <div>
                                    <p>주문 관리</p>
                                    <LinkBuilder linkMap={orderLinks} onClickEvent={closeMobileSubMenu}/>
                                </div>
                            </>
                        ) : (<>
                            <div>
                                <p>커뮤니티</p>
                                <LinkBuilder linkMap={boardLinks} onClickEvent={closeMobileSubMenu}/>
                                {userInfo && userInfo?.role != 'USER' &&
                                    <Link to={'/admin'} onClick={closeMobileSubMenu}>관리자 콘솔</Link>}
                            </div>

                            <div>
                                <p>쇼핑몰 정보</p>
                                <LinkBuilder linkMap={shopLinks} onClickEvent={closeMobileSubMenu}/>
                                <Link
                                    to={'http://pf.kakao.com/_iJGzxj/chat'}
                                    target={'_blank'}
                                >
                                    <img
                                        src={kakaoInquriyWide}
                                        alt={logoAlt}
                                        onClick={closeMobileSubMenu}
                                    />
                                </Link>
                            </div>
                        </>)
                    }
                </div>
                <div className={styles.subMenuBg + ' sub-menu-bg'} onClick={closeMobileSubMenu}></div>
            </div>
        </header>
    )
}

export default Header;