// ListViewContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

// viewMode의 타입 정의 (list 또는 grid)
type ViewMode = 'list' | 'grid';

// Context의 상태를 정의할 타입
interface ListViewContextType {
    viewMode: ViewMode;
    setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>; // setViewMode 추가
}

const defaultContextValue: ListViewContextType = {
    viewMode: 'list',
    setViewMode: () => {}, // 기본 값은 빈 함수
};

// Context 생성
const ListViewContext = createContext<ListViewContextType>(defaultContextValue);

// Context Provider 컴포넌트의 Props 타입
interface ListViewProviderProps {
    children: ReactNode;
}

// Context Provider 구현
export const ListViewProvider: React.FC<ListViewProviderProps> = ({ children }) => {
    const [viewMode, setViewMode] = useState<ViewMode>('list');


    return (
        <ListViewContext.Provider value={{ viewMode, setViewMode }}>
            {children}
        </ListViewContext.Provider>
    );
};

// Custom Hook으로 Context 값 가져오기
export const useListView = (): ListViewContextType => useContext(ListViewContext);
