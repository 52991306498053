import MainFrame, {ButtonOption} from '@Component/layout/MainFrame'
import LineChartComponent, {LineChartData} from '@Component/common/Chart/LineChart'
import {CmmDto} from '@Types/cmm'
import styles from './index.module.scss'
import {useEffect, useState} from 'react'
import {formatDate} from "@Util/dateUtile";

export const AnalyticsGraph: React.FC<{ summery?: CmmDto.Summery }> = ({
                                                                           summery,
                                                                       }) => {
    const [chartData, setChartData] = useState<LineChartData[]>([]);
    const [summeryType, setSummeryType] = useState<string>('day')
    const updateHeight = () => {
        const targetElement = document.getElementById('dashboard-item3')
        const changeElement = document.getElementById('dashboard-item2')
        if (window.innerWidth >= 1024) {
            if (targetElement && changeElement) {
                changeElement.style.height = `${
                    targetElement.offsetHeight - 22
                }px`
            }
        } else {
            if (targetElement && changeElement) {
                changeElement.style.height = `45vh`
            }
        }
    }

    // 최근 7일의 데이터를 준비하는 함수
    const getLast7DaysData = () => {
        const today = new Date()
        const last7Days = [];

        // 날짜를 최근 7일로 채우기
        for (let i = 0; i < (summeryType == 'day' ? 7 : 6); i++) {
            const date = new Date(today);
            if (summeryType == 'day') {
                date.setDate(today.getDate() - i);
            } else {
                date.setMonth(today.getMonth() - i);
            }
            last7Days.push(date.toISOString().split('T')[0]); // YYYY-MM-DD 형식으로 날짜 저장
        }

        // 안전하게 매핑된 데이터 만들기
        const dataMap: LineChartData[] = []
        const dateformat = summeryType == 'day' ? 'yy.MM.dd' : 'yy.MM'
        const data = summeryType == 'day' ? summery?.dailyAnalytics : summery?.monthAnalytics
        data?.forEach((item) => {
            dataMap.push({
                name: formatDate(item?.analyticsDate, dateformat),
                value1: item?.pageViewCount,
                value2: item?.visitorCount
            } as LineChartData)
        });

        last7Days.map(date => {
            if (!dataMap.find((item) => item.name == formatDate(date, dateformat))) {
                dataMap.push({
                    name: formatDate(date, dateformat),
                    value1: 0,
                    value2: 0
                } as LineChartData)
            }
        });

        return dataMap.reverse();
    };

    useEffect(() => {
        // 초기 실행
        updateHeight()

        // 리사이즈 이벤트 리스너 추가
        window.addEventListener('resize', updateHeight)

        // 컴포넌트 언마운트 시 리스너 제거
        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    useEffect(() => {
        updateHeight()
        const updatedChartData = getLast7DaysData();
        setChartData(updatedChartData);
    }, [summery, summeryType]);

    const action: ButtonOption[] = [
        {
            name: summeryType == 'day' ? '월간차트보기' : '일간차트보기',
            onClick: () => {
                if (summeryType == 'day') {
                    setSummeryType('mon')
                } else {
                    setSummeryType('day')
                }
            },
        },
    ]

    return (
        <MainFrame
            title={'접속 차트'}
            action={action}
            marginZero={true}
            className={styles.graph}
        >
            <LineChartComponent
                data={chartData}
                line1Name="조회수"
                line2Name="방문자수"
                line1Color="#000000"
                line2Color="#808080"
            />
        </MainFrame>
    )
}
