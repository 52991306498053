import React, { useEffect, useState } from 'react'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import MainFrame from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'
import FormField, { FormFieldProps } from '@Component/layout/FormField'

import {
    createAdminOrderUpdateDto,
    createOrderInsertDto,
    OrderDto,
} from '@Types/order'
import { isValid } from '@Util/formUtils'
import { insertOrderApi, updateAdminOrderApi } from '@Api/admin/order'
import { useNavigate } from 'react-router-dom'
import {
    formList,
    formList2,
    formList3,
} from '@Component/domains/order/OrderEdit/form'
import { saveValid, saveValid2 } from '@Component/domains/order/OrderEdit/valid'
import Button from '@Component/common/Input/Button'

interface Props {
    orderDto?: OrderDto.Response
    updateType?: string
}

const OrderEdit: React.FC<Props> = ({ orderDto, updateType }) => {
    const modal = getModalInstance()
    const navigate = useNavigate()
    const [saveDto, setSaveDto] =
        useState<OrderDto.AdminInsert>(createOrderInsertDto)
    const [formFileList, setFormFileList] = useState<FormFieldProps[]>([])

    useEffect(() => {
        let updatedFormFileList

        if (updateType == 'delivery') {
            updatedFormFileList = formList3
        } else if (updateType == 'order') {
            updatedFormFileList = [
                ...formList,
                ...(saveDto?.paymentType == 'ACCOUNT' ? formList2 : []),
            ]
        } else {
            updatedFormFileList = [
                ...formList,
                ...(saveDto?.paymentType == 'ACCOUNT' ? formList2 : []),
                ...formList3,
            ]
        }

        setFormFileList(updatedFormFileList)
    }, [updateType, saveDto?.paymentType]) // updateType과 saveDto?.paymentType 변경 시 재실행

    useEffect(() => {
        if (orderDto) {
            setSaveDto(createAdminOrderUpdateDto(orderDto))
        }
    }, [orderDto]) // updateType과 saveDto?.paymentType 변경 시 재실행

    return (
        <MainFrame
            className={styles.form}
            maxWidth={900}
            title={updateType ? '주문 수정' : `주문 등록`}
            marginZero={true}
        >
            <FormField
                formFieldList={formFileList}
                data={saveDto}
                setData={setSaveDto}
                validOption={[
                    ...saveValid,
                    ...(saveDto?.paymentType === 'ACCOUNT' ? saveValid2 : []), // 조건에 따라 formList2 추가
                ]}
            />
            <div>
                <Button
                    extraClass={styles.halfButton}
                    name={'내역 저장'}
                    onClick={async () => {
                        if (
                            (await isValid(saveDto, saveValid, true)) &&
                            (saveDto?.paymentType !== 'ACCOUNT' ||
                                (await isValid(saveDto, saveValid2, true)))
                        ) {
                            if (
                                updateType
                                    ? await updateAdminOrderApi(
                                          saveDto,
                                          updateType
                                      )
                                    : await insertOrderApi(saveDto)
                            ) {
                                modal?.alert(
                                    () => {
                                        modal?.closeModal()
                                    },
                                    '알림',
                                    '주문 내역이 저장되었습니다.'
                                )
                            }
                        }
                    }}
                />
            </div>
        </MainFrame>
    )
}

export default OrderEdit
