import React, { useEffect, useState } from 'react'
import { createPaymentDto, OrderDto } from '@Types/order'
import styles from './index.module.scss'
import formStyle from '@Css/form.module.scss'
import MainFrame from '@Component/layout/MainFrame'
import { calculatePrice, onClickPayment } from '@Service/order/OrderService'
import FormField from '@Component/layout/FormField'
import {
    formList,
    formList2,
} from '@Component/domains/order/OrderCheckOut/Payment/form'
import Button from '@Component/common/Input/Button'
import { isValid } from '@Util/formUtils'
import { paymentValid } from '@Component/domains/order/OrderCheckOut/Payment/valid'
import { checkUserInfo } from '@Service/user/UserService'
import { UserDto } from '@Types/user'
import { orderWebHookService } from '@Api/user/order'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

const OrderPayment: React.FC<{
    orderDto?: OrderDto.Response
    orderList?: OrderDto.TransformedOrder[]
}> = ({ orderDto, orderList }) => {
    const modal = getModalInstance()
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalDeliveryFee, setTotalDeliveryFee] = useState<number>(0)
    const [paymentDto, setPaymentDto] =
        useState<OrderDto.PaymentInfo>(createPaymentDto)
    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    useEffect(() => {
        calculatePrice(orderList, setTotalPrice, setTotalDeliveryFee)
        checkUserInfo(setUserInfo)
    }, [orderList])

    return (
        <MainFrame className={styles.price + ' ' + formStyle.form}>
            <div className={styles.info}>
                <div className="order-button"></div>
                <div>
                    총 상품가격{' '}
                    <span id="order-cart-price">
                        {totalPrice.toLocaleString()} 원
                    </span>
                </div>
                <div>
                    배송비{' '}
                    <span id="order-cart-delivery-fee">
                        + {totalDeliveryFee.toLocaleString()} 원
                    </span>
                </div>
                <div className="total-price">
                    총 결제금액{' '}
                    <span id="order-cart-total-price">
                        {(totalPrice + totalDeliveryFee).toLocaleString()} 원
                    </span>
                </div>

                <FormField
                    formFieldList={
                        paymentDto.transactionType != 'ACCOUNT'
                            ? formList
                            : formList2
                    }
                    data={paymentDto}
                    setData={setPaymentDto}
                    validOption={paymentValid}
                />
                <Button
                    name={'결제하기'}
                    onClick={async () => {
                        console.log(paymentDto)
                        if (!orderDto?.address) {
                            modal?.alert(
                                () => {},
                                '알림',
                                '배송지를 선택해주세요.'
                            )
                            return
                        }
                        if (
                            paymentDto.transactionType == 'ACCOUNT' &&
                            (await isValid(paymentDto, paymentValid, true))
                        ) {
                            const temp = paymentDto
                            temp.paymentId = orderDto.orderCode
                            await orderWebHookService(temp)
                        } else if (paymentDto.transactionType == 'PAYMENT') {
                            await onClickPayment(userInfo!, orderDto!) // 객체로 묶어서 전달
                        } else if (!paymentDto.transactionType) {
                            modal?.alert(
                                () => {},
                                '알림',
                                '결제수단을 선택해주세요.'
                            )
                        }
                    }}
                    black={true}
                />
            </div>
        </MainFrame>
    )
}

export default OrderPayment
