import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import React, { useEffect, useState } from 'react'
import { OrderDto } from '@Types/order'
import { getOrderApi } from '@Api/user/order'

import MainFrame from '@Component/layout/MainFrame'
import styles from './index.module.scss'
import OrderInfo from '@Component/domains/order/OrderInfo'
import OrderPayment from '@Component/domains/order/OrderCheckOut/Payment'
import { transFormItem } from '@Service/order/OrderService'
import { getAddressListApi } from '@Api/user/Address'

const OrderCheckOut = () => {
    const modal = getModalInstance()
    const [order, setOrder] = useState<OrderDto.Response>()
    const [orderItemList, setOrderItemList] =
        useState<OrderDto.TransformedOrder[]>()

    const fetchData = async () => {
        // 첫 번째 API 호출로 order 데이터 설정
        const response = await getOrderApi()
        setOrder(response)
        console.log(response)
        // orderItemList 데이터 변환 후 설정
        if (response?.orderItemList) {
            setOrderItemList(transFormItem(response.orderItemList))
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (!order) {
        return <></>
    }

    return (
        <MainFrame className={styles.orderCheckOut}>
            <OrderInfo
                orderDto={order}
                setOrderDto={setOrder}
                orderItemList={orderItemList}
            />
            <OrderPayment orderDto={order} orderList={orderItemList} />
        </MainFrame>
    )
}

export default OrderCheckOut
