import React, {useEffect, useState} from 'react'
import {getFileMap} from '@Util/imageUtils'
import ImageDrop from '@Component/common/Image/ImageDrop/ImageDrop'
import {mapResponseToSave} from '@Types/board'
import styles from '@Css/form.module.scss'
import MainFrame, {ButtonOption} from '@Component/layout/MainFrame'
import {requestValid} from '@Component/domains/board/Edit/valid'
import {getBoardApi} from '@Api/user/board'
import {Editor} from '@Component/common/Input/Editor/Editor'
import FormField, {FormFieldProps} from '@Component/layout/FormField'
import {createOrderUpdateDto, OrderDto, RequestType} from '@Types/order'
import {updateOrderApi} from "@Api/user/order";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {isValid} from "@Util/formUtils";

export const RequestEditor = (props: { orderDto?: OrderDto.Response }) => {
    const modal = getModalInstance();
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [saveDto, setSaveDto] = useState<OrderDto.UserUpdate>(
        createOrderUpdateDto(props.orderDto)
    )

    const [defaultData, setDefaultData] = useState<string>()

    const inquiryForm: FormFieldProps[] = [
        {
            label: '요청 구분',
            inputType: 'select',
            placeholder: '요청구분을 선택해주세요.',
            options: RequestType(props?.orderDto?.orderState || ''),
            name: 'orderState',
        },
        {
            label: '요청 제목',
            placeholder: 'REQUEST TITLE',
            inputType: 'text',
            name: 'title',
        },
    ]

    const actionButton: ButtonOption[] = [
        {
            name: saveDto.boardId ? '수정' : '저장',
            onClick: async () => {
                if (await isValid(saveDto, requestValid, true) && await updateOrderApi(saveDto, fileMap)) {
                    modal?.alert(() => {
                        modal?.closeModal()
                    }, '알림', '요청이 저장되었습니다.')
                }
            },
        },
    ]
    const fetchData = async (boardId?: string) => {
        if (boardId) {
            const response = await getBoardApi(boardId)
            if (response) {
                setSaveDto(mapResponseToSave(response))
                setFileMap(await getFileMap(response?.imageUrl || []))
                setDefaultData(response.content)
            }
        }
    }

    useEffect(() => {
        fetchData(saveDto.boardId)
    }, [saveDto.boardId])


    useEffect(() => {
        if (RequestType(props?.orderDto?.orderState || '').length == 0) {
            modal?.alert(() => {
                modal?.closeModal()
            }, '알림', '현재 주문이 취소/교환/반품이 불가한 상태입니다.', '필요시 관리자에게 문의해주세요.');
        }
    }, [])

    return (
        <MainFrame
            className={styles.form}
            maxWidth={900}
            title={'교환/환불요청 작성'}
            marginZero={true}
            action={actionButton}
        >
            <FormField
                formFieldList={inquiryForm}
                data={saveDto}
                setData={setSaveDto}
                validOption={requestValid}
            />
            <h3>사진 첨부</h3>
            <ImageDrop fileMap={fileMap} setFileMap={setFileMap}/>
            <h3>요청 내용</h3>
            <Editor
                name={'content'}
                data={saveDto}
                setData={setSaveDto}
                userSet={true}
                height={300}
                defaultData={defaultData}
            />
        </MainFrame>
    )
}