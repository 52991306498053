import React, { ReactNode, useEffect } from 'react'
import styles from './index.module.scss'
import Button from '@Component/common/Input/Button'

export interface ButtonOption {
    name: string
    onClick: () => void
}

interface MainFrameProps {
    title?: React.ReactNode
    className?: string
    maxWidth?: number
    width?: string
    children?: ReactNode
    documentTitle?: string
    marginZero?: boolean
    id?: string
    action?: ButtonOption[]
    rightNode ?: React.ReactNode
    hideSiteName?: boolean
    style?: React.CSSProperties
}

const MainFrame: React.FC<MainFrameProps> = ({
    title,
    className = '',
    maxWidth = 1200,
    width,
    children,
    documentTitle = '',
    marginZero,
    id,
    action,
    hideSiteName,
    style,
    rightNode
}) => {
    const containerStyle: React.CSSProperties = {
        maxWidth: `${maxWidth}px`,
        margin: marginZero ? '0 auto' : undefined,
        width: width,
        ...style,
    }

    useEffect(() => {
        document.title = `${process.env.SITE_NAME} ${documentTitle}`
        // 메타 태그 설정
        const metaDescription = document.createElement('meta')
        metaDescription.name = 'description'
        metaDescription.content = `${process.env.SITE_NAME} ${documentTitle} 페이지 입니다. ${process.env.SITE_DESCRIPTION}`
        document.head.appendChild(metaDescription)

        return () => {
            document.head.removeChild(metaDescription)
        }
    }, [documentTitle])

    return (
        <div
            className={`${styles.content} ${className}`}
            style={containerStyle}
            id={id}
        >
            {title && (
                <h3
                    className={styles.title}
                    style={{ margin: marginZero ? '0 auto' : undefined }}
                >
                    {hideSiteName ? (
                        title
                    ) : (
                        <>
                            {process.env.SITE_NAME}
                            <span>{title}</span>
                        </>
                    )}
                    <div className={styles.buttonArea}>
                        {action?.map((option, index) => (
                            <Button
                                key={index}
                                name={option.name}
                                onClick={option.onClick}
                            />
                        ))}
                        {rightNode}
                    </div>
                </h3>
            )}
            {children}
        </div>
    )
}

export default MainFrame
