import { UserDto } from '@Types/user'
import { HttpAlert, HttpSuccess } from '@Types/cmm/HttpCallBack'
import { deleteRequest, getRequest, postRequest, putRequest } from '@Api/Api'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'

export const checkDuplicateApi = async (
    type: string,
    duplication: UserDto.Duplication
): Promise<boolean> => {
    try {
        await postRequest(`/user/check/${type}`, duplication)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const userJoinApi = async (user: UserDto.Join) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                'BROSP 가입을 환영합니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {
        403: () => {
            modal?.alert(() => {}, '알림', '인증번호가 틀립니다.')
        },
    }
    try {
        await postRequest('/user', user, httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}

export const userLoginApi = async (loginDto: UserDto.Login) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal?.alert(
                () => {
                    localStorage.setItem('userLogin', 'Y')
                    const referrer = localStorage.getItem('referrer')
                    window.location.href = referrer != null ? referrer : '/'
                },
                '알림',
                '환영합니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {
        401: () => {
            modal?.alert(() => {}, '알림', '아이디 또는 비밀번호가 틀립니다.')
        },
    }
    try {
        await postRequest('/token', loginDto, httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}

let userInfo: UserDto.Info
export const getUserInfo = async (refresh?: boolean) => {
    const modal = getModalInstance()
    try {
        if (userInfo && userInfo.userName != '' && !refresh) {
            return userInfo
        }
        userInfo = await getRequest<UserDto.Info>('/user')

        return userInfo || undefined
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const userLogoutApi = async () => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal?.alert(
                () => {
                    localStorage.setItem('userLogin', 'N')
                    const referrer = localStorage.getItem('referrer')
                    if (
                        referrer?.includes('mypage') ||
                        referrer?.includes('order')
                    ) {
                        window.location.href = '/'
                    } else {
                        window.location.href = referrer != null ? referrer : '/'
                    }
                    userInfo.userName = ''
                },
                '알림',
                '감사합니다.',
                '다음에 더 좋은 상품으로 찾아뵙겠습니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}
    try {
        await deleteRequest('/token', httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}

export const userUpdateApi = async (updateDto: UserDto.Update) => {
    try {
        await putRequest('/user', updateDto)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const userFindApi = async (findDto: UserDto.Find) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: (data) => {
                modal?.alert(
                    () => {},
                    '알림',
                    '아이디를 찾았습니다',
                    'ID : ' + data
                )
            },
        }
        const httpAlert: HttpAlert = {
            404: () => {
                modal?.alert(
                    () => {},
                    '알림',
                    '해당하는 ID가 존재하지 않습니다.'
                )
            },
        }
        await postRequest('/user/find', findDto, httpSuccess, httpAlert)
    } catch (e) {
        console.log(e)
    }
}




export const userResetApi = async (reset: UserDto.Reset) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal?.alert(
                    () => {window.location.href = '/user/login'},
                    '알림',
                    '비밀번호가 재설정되었습니다.',
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal?.alert(
                    () => {},
                    '알림',
                    '인증토큰이 일치하지 않습니다.'
                )
            },
            404: () => {
                modal?.alert(
                    () => {},
                    '알림',
                    '해당하는 계정이 존재하지 않습니다.',
                    '소셜 연동계정인 경우 비밀번호 재설정이 불가합니다.'
                )
            },
        }
        await putRequest('/user/reset', reset, httpSuccess, httpAlert)
    } catch (e) {
        console.log(e)
    }
}


export const userDeleteApi = async () => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal?.alert(
                () => {
                    localStorage.setItem('userLogin', 'N')
                    const referrer = localStorage.getItem('referrer')
                    if (
                        referrer?.includes('mypage') ||
                        referrer?.includes('order')
                    ) {
                        window.location.href = '/'
                    } else {
                        window.location.href = referrer != null ? referrer : '/'
                    }
                    userInfo.userName = ''
                },
                '알림',
                '회원탈퇴되었습니다.',
                '다음에 더 좋은 상품으로 찾아뵙겠습니다.'
            )
        },
    }
    try {
        await deleteRequest('/user', httpSuccess)
    } catch (e) {
        console.log(e)
    }
}
