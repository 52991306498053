import { getModalInstance } from '@Component/common/Modal/ModalProvider'
// import {ProductDetail, ProductListResponse, ProductSearchDTO} from "@Types/product/productDto";
import { HttpAlert, HttpSuccess } from '@Types/cmm/HttpCallBack'
import { getRequest } from '@Api/Api'
import { ProductDto } from '@Types/product'

export const getProductListApi = async (searchQuery: ProductDto.Search) => {
    const modal = getModalInstance()

    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            204: () => {
                modal!.alert(() => {}, '알림', '조회된 상품이 없습니다.')
            },
        }

        const config = {
            params: searchQuery,
        }
        return await getRequest<ProductDto.Page>(
            '/product/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getProductApi = async (
    productId: string,
    orderProduct?: boolean,
    view?: boolean
) => {
    const modal = getModalInstance()

    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<ProductDto.Response>(
            '/product/' +
                productId +
                `?isOrderProduct=${orderProduct ? 'true' : 'false'}` +
                `&view=${view ? 'true' : 'false'} `,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
