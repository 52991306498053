import ReactDOM from 'react-dom/client'
import '@Css/cmm.css'
import Router from './Router'
import '@Css/fontello.css'
import ReactModal from 'react-modal'
import {ListViewProvider} from "./context/board/ListViewContext";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
ReactModal.setAppElement('#root')

root.render(
    <ListViewProvider>
       <Router />
    </ListViewProvider>
)
