import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import React, { useEffect, useState } from 'react'
import {
    createAdminOrderUpdateDto,
    createOrderUpdateDto,
    OrderDto,
    stateOptions,
} from '@Types/order'
import { updateOrderApi } from '@Api/user/order'
import { AddressDto } from '@Types/address'
import MainFrame, { ButtonOption } from '@Component/layout/MainFrame'
import OrderDeliveryInfo from '@Component/domains/order/OrderInfo/DetailInfo/OrderDeliveryInfo'
import OrderItemList from '@Component/domains/order/OrderInfo/DetailInfo/OrderItemList'
import OrderPaymentInfo from '@Component/domains/order/OrderInfo/DetailInfo/OrderPaymentInfo'
import OrderRequestList from '@Component/domains/order/OrderInfo/DetailInfo/OrderRequestList'
import { UserInfoView } from '@Component/domains/user/UserInfoView'
import OrderRequestInfoList from '@Component/domains/order/OrderInfo/DetailInfo/OrderRequestInfo'
import { updateAdminOrderApi } from '@Api/admin/order'
import OrderEdit from '@Component/domains/order/OrderEdit'
import CancelPaymentModal from '../CancelPaymentModal'

interface Props {
    orderDto?: OrderDto.Response
    setOrderDto?: React.Dispatch<
        React.SetStateAction<OrderDto.Response | undefined>
    >
    orderItemList?: OrderDto.TransformedOrder[]
    isAdmin?: boolean
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

export const OrderInfo: React.FC<Props> = ({
    orderDto,
    orderItemList,
    setOrderDto,
    isAdmin,
    setRefresh,
}) => {
    if (!orderDto) {
        return <></>
    }

    const modal = getModalInstance()
    const [saveDto, setSaveDto] = useState<OrderDto.UserUpdate>(
        createOrderUpdateDto(orderDto)
    )
    const [adminSaveDto, setAdminSaveDto] = useState<OrderDto.AdminUpdate>(
        createAdminOrderUpdateDto(orderDto)
    )
    const [addressDto, setAddressDto] = useState<AddressDto.Response>()
    const updateAddress = async () => {
        const updatedSaveDto = {
            ...saveDto,
            address: addressDto,
        }
        setSaveDto(updatedSaveDto)
        await updateOrderApi(updatedSaveDto)
    }

    const handleSelectChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = event.target.value

        setAdminSaveDto((prevDto) => ({
            ...prevDto,
            orderState: selectedValue, // 변경된 orderState 값 설정
        }))
    }

    const actionButton: ButtonOption[] = [
        ...(isAdmin
            ? [
                  {
                      name: '상태변경',
                      onClick: async () => {
                          if (orderDto.orderState == adminSaveDto.orderState) {
                              modal?.alert(
                                  () => {},
                                  '알림',
                                  '변경상태가 현재와 같습니다.'
                              )
                              return
                          }
                          if (
                              (await updateAdminOrderApi(
                                  adminSaveDto,
                                  'state'
                              )) &&
                              setRefresh
                          ) {
                              modal?.alert(
                                  () => {},
                                  '알림',
                                  '상태가 변경되었습니다.'
                              )
                              setRefresh(true)
                          }
                      },
                  },
                  ...(!orderDto?.user
                      ? [
                            {
                                name: '주문수정',
                                onClick: () => {
                                    modal?.popup(
                                        <OrderEdit
                                            orderDto={orderDto}
                                            updateType={'order'}
                                        />,
                                        '900px',
                                        () => {
                                            if (setRefresh) setRefresh(true)
                                        }
                                    )
                                },
                            },
                        ]
                      : []),
                  ...(orderDto?.user && orderDto.paymentType != 'ACCOUNT'
                      ? [
                            {
                                name: '환불하기',
                                onClick: () => {
                                    modal?.popup(
                                        <CancelPaymentModal
                                            orderDto={orderDto}
                                        />,
                                        '500px',
                                        () => {
                                            if (setRefresh) setRefresh(true)
                                        }
                                    )
                                },
                            },
                        ]
                      : []),
              ]
            : []),
    ]

    useEffect(() => {
        if (addressDto) {
            updateAddress()
            if (setOrderDto)
                setOrderDto({
                    ...orderDto,
                    ...addressDto,
                })
        }
    }, [addressDto])

    return (
        <MainFrame
            marginZero={true}
            action={actionButton}
            hideSiteName={true}
            title={
                isAdmin && (
                    <select
                        value={adminSaveDto.orderState}
                        onChange={handleSelectChange}
                    >
                        <option>-변경 상태-</option>
                        {stateOptions(
                            orderDto?.user ? 'Order' : 'AdminOrder',
                            orderDto.orderState
                        ).map((item) => (
                            <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                )
            }
        >
            {isAdmin && (
                <UserInfoView isAdmin={isAdmin} userInfo={orderDto.user} />
            )}
            <OrderRequestInfoList
                currentState={orderDto.orderState}
                orderRequestList={orderDto.orderRequestList}
                isAdmin={isAdmin}
                setRefresh={setRefresh}
            />

            <OrderRequestList
                orderDto={orderDto}
                setRefresh={setRefresh}
                isAdmin={isAdmin}
            />

            <OrderPaymentInfo orderDto={orderDto} isAdmin={isAdmin} />
            <OrderDeliveryInfo
                orderDto={orderDto}
                addressDto={addressDto}
                setAddressDto={setAddressDto}
                setRefresh={setRefresh}
                isAdmin={isAdmin}
            />
            <OrderItemList orderItemList={orderItemList} />
        </MainFrame>
    )
}

export default OrderInfo
