import { ProductDto } from '@Types/product'

export namespace CmmDto {
    export interface Summery {
        dailyAnalytics?: Analytics[] // 일간통계
        monthAnalytics?: Analytics[] // 월간통계
        orderCounts?: Count // 주문 상태별 개수
        dailyProduct?: ProductDto.Response[] // 일간 인기 상품
        monthProduct?: ProductDto.Response[] // 월간 인기 상품
    }

    export interface Count {
        [key: string]: number | undefined
        payment?: number // 결제완료 개수
        deposit?: number // 입금대기 개수
        preparing?: number // 준비중 개수
        cancellation?: number // 취소요청 개수
        exchange?: number // 교환요청 개수
        exchanging?: number // 교환중 개수
        refundRe?: number // 반품요청 개수
        refunding?: number // 반품증 개수
        review?: number // 답변안단리뷰 개수
        inquiry?: number // 답변안단 문의 개수
    }
    export interface Analytics {
        analyticsDate?: string // 분석 날짜 (Java의 LocalDate는 JavaScript의 Date로 변환)
        visitorCount?: number // 방문자 수
        pageViewCount?: number // 페이지 조회수
        orderCount?: number // 주문 수
        revenue?: number // 매출액
        inquiryCount?: number // 문의 수
        reviewCount?: number // 리뷰 수
        userCount?: number // 가입자 수
    }
}

// 변수명과 한글 이름 매핑 자료구조
export const summeryMap: Map<string, string> = new Map([
    ['payment', '결제완료'],
    ['deposit', '입금대기'],
    ['preparing', '준비중'],
    ['cancellation', '취소요청'],
    ['exchange', '교환요청'],
    ['exchanging', '교환중'],
    ['refundRe', '반품요청'],
    ['refunding', '반품증'],
    ['review', '미답변 리뷰'],
    ['inquiry', '미답변 문의'],
])
