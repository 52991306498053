import React, {useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import './3dCanvas.css';

const CanvasView = ({imageUrl, model: modelType}: { imageUrl: string; model: string }) => {
    const mountRef = useRef<HTMLDivElement>(null);
    const [scene, setScene] = useState<THREE.Scene | null>(null);
    const [camera, setCamera] = useState<THREE.PerspectiveCamera | null>(null);
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>(null);
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const [model, setModel] = useState<THREE.Group | null>(null);
    const defaultPositionRef = useRef<THREE.Vector3>(new THREE.Vector3());

    const loadTexture = (url: string): THREE.Texture => {
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(url);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.colorSpace = THREE.SRGBColorSpace;
        return texture;
    };

    const setupScene = () => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(70, 1, 0.1, 10000);
        const renderer = new THREE.WebGLRenderer({antialias: true});

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(mountRef.current!.clientHeight, mountRef.current!.clientHeight);
        renderer.setClearColor(0xd6d6d6);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        mountRef.current!.appendChild(renderer.domElement);

        setScene(scene);
        setCamera(camera);
        setRenderer(renderer);

        return {scene, camera, renderer};
    };

    const setupLights = (scene: THREE.Scene) => {
        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight1.position.set(10, 3, 100).normalize();
        scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight2.position.set(10, 3, -100).normalize();
        scene.add(directionalLight2);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);
    };

    const loadModel = (scene: THREE.Scene, camera: THREE.PerspectiveCamera) => {
        let modelUrl = '';
        const position = new THREE.Vector3();

        switch (true) {
            case modelType.includes('BasketBall'):
                modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model.gltf';
                position.set(50, 5.49, 74.37);
                break;
            case modelType.includes('T-Shirt'):
                modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model2.gltf';
                position.set(-0.2, 0.4, 0.6);
                break;
            case modelType.includes('Shirt'):
                modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model3.gltf';
                position.set(-0.2, 0.1, 0.7);
                break;
            case modelType.includes('HoodT'):
                modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model4.gltf';
                position.set(0.8, 0.1, 0.3);
                break;
        }

        defaultPositionRef.current.copy(position);
        camera.position.copy(position);

        const loader = new GLTFLoader();
        loader.load(
            modelUrl,
            (gltf) => {
                const loadedModel = gltf.scene;
                scene.add(loadedModel);
                setModel(loadedModel);

                const box = new THREE.Box3().setFromObject(loadedModel);
                const center = new THREE.Vector3();
                box.getCenter(center);
                loadedModel.position.sub(center);

                const texture = loadTexture(imageUrl);
                loadedModel.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        const material = child.material as THREE.MeshStandardMaterial;
                        if (material.map) material.map.dispose();
                        material.map = texture;
                        material.roughness = 0.6;
                        material.metalness = 0.1;
                        material.needsUpdate = true;
                    }
                });
            },
            undefined,
            (error) => console.error('Model loading error:', error)
        );
    };

    const handleResize = () => {
        if (camera && renderer) {
            const size = mountRef.current!.clientHeight;
            renderer.setSize(size, size);
            camera.aspect = 1;
            camera.updateProjectionMatrix();
        }
    };

    const handleModelRotation = () => {
        if (model) {
            model.rotation.y += Math.PI / 2;
        }
    };

    useEffect(() => {
        if (!imageUrl || !modelType || !mountRef.current) return;

        const {scene, camera, renderer} = setupScene();
        setupLights(scene);
        loadModel(scene, camera);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.rotateSpeed = 1.0;
        setControls(controls);

        const animate = () => {
            controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (controls) controls.dispose();
            if (renderer) renderer.dispose();
            if (model) {
                model.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.geometry.dispose();
                        if (Array.isArray(child.material)) {
                            child.material.forEach((material) => material.dispose());
                        } else {
                            child.material.dispose();
                        }
                    }
                });
                scene.remove(model);
            }
            if (mountRef.current) {
                while (mountRef.current.firstChild) {
                    mountRef.current.removeChild(mountRef.current.firstChild);
                }
            }
            setScene(null);
            setCamera(null);
            setRenderer(null);
            setControls(null);
            setModel(null);
        };
    }, [imageUrl, modelType]);

    return (
        <React.Fragment>
            <div ref={mountRef} className="view-canvas">
                <input
                    type="button"
                    value="↺"
                    className="bg-white-button"
                    onClick={handleModelRotation}
                />
            </div>
            <span style={{fontSize: 'var(--font-size-0-9)', color: 'blue'}}>
                3D 이미지와 실제상품은 차이가 있을 수 있습니다.
            </span>
        </React.Fragment>
    );
};

export default CanvasView;
