import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'

export interface LineChartData {
    name: string
    value1: number
    value2: number
}

interface LineChartProps {
    data: LineChartData[]
    line1Name?: string
    line2Name?: string
    line1Color?: string
    line2Color?: string
}

const LineChartComponent: React.FC<LineChartProps> = ({
                                                          data,
                                                          line1Name,
                                                          line2Name,
                                                          line1Color = '#000000',
                                                          line2Color = '#808080',
                                                      }) => {
    const getYaxisDomain = (
        data: LineChartData[],
        key: keyof LineChartData
    ) => {
        if (!data || data.length === 0) {
            return [0, 10]
        }

        const values = data.map((item) => +item[key])
        const min = Math.min(...values)
        const max = Math.max(...values)
        const range = max - min

        // step을 5로 고정하여, 5의 배수로 설정
        const step = Math.ceil(range / 5) || 1
        const adjustedMin = Math.floor(min / 5) * 5
        const adjustedMax = Math.ceil(max / 5) * 5

        return [adjustedMin, adjustedMax]
    }

    const value1Domain = getYaxisDomain(data, 'value1')
    const value2Domain = getYaxisDomain(data, 'value2')

    if (!data || data.length === 0) {
        return <div>No data available</div>
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={value1Domain} tickCount={6} />
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    domain={value2Domain}
                    axisLine={false}
                    tickLine={false}
                    tickCount={6}
                />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="value1"
                    name={line1Name}
                    stroke={line1Color}
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                />
                <Line
                    type="monotone"
                    dataKey="value2"
                    name={line2Name}
                    stroke={line2Color}
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                    yAxisId="right"
                />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default LineChartComponent
