import { useParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
// import {ProductService} from "@Api/user/product/ProductService";
import CanvasView from '@Component/common/3D/3dCanvas'
import { ProductInfo } from '@Component/domains/product/ProductInfo/ProductInfo'
import ReviewList from '@Component/domains/board/List/ReviewList'
import InquiryList from '@Component/domains/board/List/InquiryList'
import { ProductPeriod } from '@Component/domains/cmm/Document/Period/ProductPeriod'
import { setWishicon } from '@Service/wish/WishService'
import { getAdminProduct } from '@Api/admin/product'
import { ProductDto } from '@Types/product'
import { getProductApi } from '@Api/user/product'
import styles from '@Css/product/product.module.scss'
import { decodeHTML } from '@Util/stringUtils'
import MainFrame from '@Component/layout/MainFrame'
import { setViewedProduct } from '@Service/product'
import ViewChangeButton from "@Component/domains/board/ViewChangeButton";

type Section =
    | 'product-main'
    | 'product-review'
    | 'product-inquiry'
    | 'product-period'

const SectionList = [
    'product-main',
    'product-review',
    'product-inquiry',
    'product-period',
]

function ProductDetail(props: { orderProduct?: boolean }) {
    const { productId } = useParams<{ productId: string }>()
    const [product, setProduct] = useState<ProductDto.Response>()
    const isClick = useRef<boolean>(false)

    const handleClick = (
        section: Section,
        event: React.MouseEvent<HTMLElement>
    ) => {
        isClick.current = true
        const element = document.getElementById(section)
        const menuHeight = document
            .querySelectorAll('.product-menu')
            .item(0).clientHeight

        if (element) {
            const topOffset =
                element.getBoundingClientRect().top +
                window.scrollY -
                menuHeight
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            })

            document
                .querySelectorAll('.select-menu')
                .forEach((el) => el.classList.remove('select-menu'))
            event.currentTarget.classList.add('select-menu')

            // 해당 섹션에 도달하는 시간을 고려하여 타이머 설정
            const scrollPosition = window.scrollY + window.innerHeight / 2
            const offsetTop = element.offsetTop
            const lockTime = Math.abs(offsetTop - scrollPosition) / 2 // 속도 조절

            setTimeout(() => {
                isClick.current = false
            }, lockTime)
        }
    }

    const handleScroll = throttle(() => {
        if (isClick.current) return

        const menuElement = document
            .querySelectorAll('.product-menu')
            .item(0) as HTMLElement | null
        if (!menuElement) return // 메뉴 엘리먼트가 없으면 종료

        const menuHeight = (
            document.querySelectorAll('.product-menu').item(0) as HTMLElement
        ).clientHeight
        const scrollPosition = window.scrollY + window.innerHeight / 2

        let productInfoVisible = false
        const rect = (
            document.querySelectorAll('.product-info').item(0) as HTMLElement
        ).getBoundingClientRect()
        const offsetTop = rect.top + window.scrollY - menuHeight
        const offsetBottom = rect.bottom + window.scrollY - menuHeight

        if (offsetBottom > window.scrollY) {
            productInfoVisible = true
        }

        const headerElement = document.querySelector('header')
        if (headerElement) {
            if (!productInfoVisible) {
                headerElement.style.transform = 'translateY(-100%)'
                headerElement.style.transition = 'transform 0.3s ease-in-out'
            } else {
                headerElement.style.transform = 'translateY(0)'
                headerElement.style.transition = 'transform 0.3s ease-in-out'
            }
        }

        SectionList.forEach((sectionId, index) => {
            const element = document.getElementById(sectionId)
            if (element) {
                const sectionOffsetTop =
                    element.getBoundingClientRect().top + window.scrollY
                const sectionOffsetBottom =
                    element.getBoundingClientRect().bottom + window.scrollY

                if (
                    scrollPosition > sectionOffsetTop &&
                    scrollPosition < sectionOffsetBottom &&
                    !isClick.current
                ) {
                    document
                        .querySelectorAll('.select-menu')
                        .forEach((el) => el.classList.remove('select-menu'))
                    document
                        .getElementById(SectionList[index] + '-menu')!
                        .classList.add('select-menu')
                }
            }
        })
    }, 100) // 주기를 더 줄여서 부드럽게 동작하도록 개선

    const fetchProduct = async () => {
        const modal = getModalInstance()
        if (productId) {
            if (productId == '1') {
                const result = await getAdminProduct(productId)
                setProduct(result)
            } else {
                const result = await getProductApi(
                    productId,
                    props.orderProduct,
                    true
                )
                if (result) {
                    setProduct(result)
                } else {
                    modal?.alert(
                        () => {
                            window.location.href = '/'
                        },
                        '알림',
                        '삭제되거나 없는 상품입니다.'
                    )
                }
            }
        }
    }

    useEffect(() => {
        fetchProduct()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            const headerElement = document.querySelector('header')!
            headerElement.style.transform = 'translateY(0)'
            headerElement.style.transition = 'transform 0.3s ease-in-out'
        }
    }, [productId])

    useEffect(() => {
        if (product) {
            setWishicon()
            setViewedProduct(productId)
        }
    }, [product])

    const ProductMenu = () => {
        return (
            <div className={styles.menu + ' product-menu'}>
                <div
                    id={'product-main-menu'}
                    className={'select-menu'}
                    onClick={(event) => handleClick('product-main', event)}
                >
                    상품설명
                </div>
                <div
                    id={'product-review-menu'}
                    onClick={(event) => handleClick('product-review', event)}
                >
                    상품리뷰
                </div>
                <div
                    id={'product-inquiry-menu'}
                    onClick={(event) => handleClick('product-inquiry', event)}
                >
                    상품문의
                </div>
                <div
                    id={'product-period-menu'}
                    onClick={(event) => handleClick('product-period', event)}
                >
                    배송안내
                </div>
            </div>
        )
    }

    const ProductMain = () => {
        // 개선된 splitStringAndExtractSrc 함수 사용
        const parts = splitStringAndExtractSrc(
            product?.productDescription
                ? decodeHTML(product?.productDescription)
                : ''
        )

        return (
            <div className={styles.main} id="product-main">
                {parts.map((part, index) => {
                    if (part.type === 'text') {
                        return (
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        part.content?.replaceAll(
                                            '/product/',
                                            process.env.PRODUCT_IMAGE_BASE_URL!
                                        ) || '',
                                }}
                            />
                        )
                    } else if (part.type === 'image') {
                        return (
                            <CanvasView
                                key={index}
                                imageUrl={part.src || ''}
                                model={part.alt || ''}
                            />
                        )
                    }
                    return null
                })}
            </div>
        )
    }

    const ProductFooter = () => {
        return (
            <div className={styles.footer}>
                <MainFrame
                    title={product?.name + ' 상품 리뷰'}
                    maxWidth={1200}
                    id={'product-review'}
                    rightNode={<ViewChangeButton/>}
                >
                    <ReviewList
                        productId={
                            props.orderProduct ? '-1' : product?.productId
                        }
                        write={!props.orderProduct}
                        moreView={!props.orderProduct}
                    />
                </MainFrame>
                <MainFrame
                    title={product?.name + ' 상품 문의'}
                    maxWidth={1200}
                    id={'product-inquiry'}
                >
                    <InquiryList
                        productId={
                            props.orderProduct ? '-1' : product?.productId
                        }
                        write={!props.orderProduct}
                        moreView={!props.orderProduct}
                    />
                </MainFrame>
                <MainFrame
                    title={'배송 정보'}
                    maxWidth={1200}
                    id={'product-period'}
                >
                    <ProductPeriod />
                </MainFrame>
            </div>
        )
    }

    return (
        <div className={styles.productDetail}>
            <ProductInfo product={product} orderProduct={props.orderProduct} />
            <ProductMenu />
            <ProductMain />
            <ProductFooter />
        </div>
    )
}

export default ProductDetail

function splitStringAndExtractSrc(inputString: string) {
    const imgTagRegex = /<img[^>]*style="[^"]*width:\s*25%;[^"]*"[^>]*>/g
    const srcRegex = /src="([^"]*)"/
    const altRegex = /alt="([^"]*)"/

    let match
    const parts = []
    let lastIndex = 0

    // 모든 <img> 태그를 찾기
    while ((match = imgTagRegex.exec(inputString)) !== null) {
        const imgTag = match[0]
        const startIndex = match.index
        const endIndex = startIndex + imgTag.length

        // <img> 태그에서 src 값 추출
        const srcMatch = imgTag.match(srcRegex)
        const srcValue = srcMatch
            ? srcMatch[1].replace(
                  '/product/',
                  process.env.PRODUCT_IMAGE_BASE_URL!
              )
            : ''

        // <img> 태그에서 alt 값 추출
        const altMatch = imgTag.match(altRegex)
        const altValue = altMatch ? altMatch[1] : ''

        // 이전 문자열 추가
        if (startIndex > lastIndex) {
            parts.push({
                type: 'text',
                content: inputString.substring(lastIndex, startIndex),
            })
        }

        // 이미지 태그 추가
        parts.push({
            type: 'image',
            src: srcValue,
            alt: altValue,
        })

        lastIndex = endIndex
    }

    // 나머지 문자열 추가
    if (lastIndex < inputString.length) {
        parts.push({
            type: 'text',
            content: inputString.substring(lastIndex),
        })
    }

    return parts
}
