import React, { useEffect, useState } from 'react'

import { getOrderApi } from '@Api/user/order'

import { getModalInstance } from '@Component/common/Modal/ModalProvider'

import { OrderDto } from '@Types/order'
import OrderInfo from '@Component/domains/order/OrderInfo'
import { transFormItem } from '@Service/order/OrderService'
import MainFrame from '@Component/layout/MainFrame'
import { getAdminOrderApi } from '@Api/admin/order'

const OrderView = (props: { orderCode: string; isAdmin?: boolean }) => {
    const [order, setOrder] = useState<OrderDto.Response>()
    const [orderItemList, setOrderItemList] =
        useState<OrderDto.TransformedOrder[]>()
    const [refresh, setRefresh] = useState<boolean>(true)
    const fetchData = async () => {
        // 첫 번째 API 호출로 order 데이터 설정
        const response = props.isAdmin
            ? await getAdminOrderApi(props.orderCode)
            : await getOrderApi(props.orderCode)
        setOrder(response)

        // orderItemList 데이터 변환 후 설정
        if (response?.orderItemList) {
            setOrderItemList(transFormItem(response.orderItemList))
        }
    }

    useEffect(() => {
        if (refresh) {
            fetchData()
            setRefresh(false)
        }
    }, [props.orderCode, refresh])

    return (
        <MainFrame marginZero={true}>
            <OrderInfo
                orderDto={order}
                orderItemList={orderItemList}
                isAdmin={props.isAdmin}
                setRefresh={setRefresh}
            />
        </MainFrame>
    )
}

export default OrderView
