// src/types/userDto.ts

import {validType} from '@Types/user/userType'

// UserDto를 namespace로 정의
export namespace UserDto {

    export interface Search {
        page?: number
        rowSize?: number
        loginId?: string
        userName?: string
        email?: string
        phoneNumber?: string
    }

    // 사용자 정보 DTO
    export interface Info {
        userId?: string | number
        email?: string
        userName?: string
        phoneNumber?: string
        userIp?: string
        role?: string
        kakaoId?: string
    }

    // 회원가입 DTO
    export interface Join {
        loginId?: string
        password?: string
        email?: string
        userName?: string
        phoneNumber?: string
        agreement?: string
        privacy?: string
        authToken?: string
    }

    export interface Cert {
        loginId?: string
        phoneNumber?: string
        email?: string
    }

    // 로그인 DTO
    export interface Login {
        loginId?: string
        password?: string
    }

    // 사용자 정보 업데이트 DTO
    export interface Update {
        password?: string
        passwordReEnter?: string

        email?: string
        userName?: string
        phoneNumber?: string
    }

    // 사용자 정보 찾기 DTO
    export interface Find {
        email?: string
        userName?: string
        phoneNumber?: string
    }

    // 비밀번호 재설정 DTO
    export interface Reset {
        password?: string
        email?: string
        loginId?: string
        authToken?: string
        phoneNumber?: string
    }

    // 중복 확인 요청 DTO
    export interface Duplication {
        loginId?: string
        email?: string
        phone?: string
        userIp?: string
    }

    // 사용자 토큰 정보 DTO
    export interface Token {
        userId?: number
        accToken?: string
        refToken?: string
        validType?: validType
    }

    export interface Page {
        content?: Info[]
        totalElements?: number
    }
    export const isAdmin = (userInfo?: UserDto.Info) => {
        return ['SYS', 'ADMIN', 'ROOT_ADMIN'].includes(userInfo?.role || '');
    }

}


