import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getRequest, postRequest} from "@Api/Api";
import {BoardDto} from "@Types/board";
import {formatDate} from "@Util/dateUtile";
import {AnswerDto} from "@Types/answer";


export const saveAdminBoardApi = async (formData: FormData) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        modal?.closeModal()
                    },
                    '알림',
                    '게시글이 저장되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal!.alert(() => {
                }, '경고', '권한이 없습니다.')
            },
        }

        return await postRequest('/admin/board', formData, httpSuccess, httpAlert,)
    } catch (e) {
        console.log(e)
    }
}

export const getAdminBoardListApi = async (searchQuery: BoardDto.Search) => {
    const modal = getModalInstance()
    try {
        searchQuery.startDate = formatDate(searchQuery?.startDate?.toString())
        searchQuery.endDate = formatDate(searchQuery?.endDate?.toString())
        if (searchQuery.boardState == 'delete')
            searchQuery.delete = true
        if (searchQuery.boardState == 'answer')
            searchQuery.answer = true
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<BoardDto.Page>(
            '/admin/board/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getAdminBoardApi = async (boardId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            410: () => {
                modal!.alert(() => {
                }, '알림', '삭제된 게시물입니다.')
            },
        }

        return await getRequest<BoardDto.Response>(
            '/admin/board/' + boardId,
            httpSuccess,
            httpAlert,
        )
    } catch (e) {
        console.log(e)
    }
}

export const saveAnswerApi = async (answer: AnswerDto.Save) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                       modal?.closeModal()
                    },
                    '알림',
                    '답변이 저장되었습니다.'
                )
            },
        }

        return await postRequest(
            '/admin/answer',
            answer,
            httpSuccess,
        )
    } catch (e) {
        console.log(e)
    }
}
