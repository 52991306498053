import {getCategoryListApi} from "@Api/cmm";

export namespace CategoryDto {

    export interface Insert {
        primaryName: string; // 1차 카테고리명
        secondaryName: string; // 2차 카테고리명
    }

    export interface UpdateSd {
        categoryId: string; // 카테고리 ID
        secondaryName: string; // 메뉴 이름
        menuOrderSd?: number; // 메뉴 순서
    }

    export interface UpdatePm {
        afterName: string; // 변경 후 1차 카테고리
        beforeName: string; // 변경 전 1차 카테고리
    }

    export interface Response {
        categoryId: string; // 카테고리 ID
        primaryName: string; // 1차 카테고리
        secondaryName: string; // 2차 카테고리
        menuOrderPm: number; // 메뉴 순서 (1차)
        menuOrderSd: number; // 메뉴 순서 (2차)
    }
}


export  interface  CategoryList { [key: string]: CategoryDto.Response[] }

export const findCategoryById = async (
    primary: string,
    secondary: string
) => {
    const categoryList = await getCategoryListApi();
    const categories = categoryList[primary]

    if (categories) {
        return (
            categories.find(
                (category) => category.secondaryName === secondary
            ) || null
        )
    }
    return null
}


// categoryId에 해당하는 키를 찾는 함수
export const findPrimary =  (categoryList: CategoryList, categoryId: string) =>  {

    for (const key in categoryList) {
        if (categoryList[key].some(category => category.categoryId == categoryId)) {

            return key; // categoryId가 포함된 키를 반환
        }
        if(categoryId== '1'){
            return '비공개'
        }
    }
    return undefined; // 해당하는 키가 없을 경우
};