import { createSearchDto, OrderDto } from '@Types/order'
import { useEffect, useState } from 'react'
import WishView from '@Component/domains/product/Wish'
import { ViewProduct } from '@Component/domains/product/ViewProduct'
import { checkUserInfo, kakaoLogin } from '@Service/user/UserService'
import Button from '@Component/common/Input/Button'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { getOrderListApi } from '@Api/user/order'
import { ActionField } from '@Component/layout/SearchField'
import MainFrame from '@Component/layout/MainFrame'

import { PageBar } from '@Component/common/PageBar'
import { OrderTableList } from '@Component/domains/order/OrderList/ListType/table'
import { getAdminOrderListApi } from '@Api/admin/order'

const UserOrderInfo: React.FC<{
    userId?: string | number
    isAdmin?: boolean
}> = ({ userId, isAdmin }) => {
    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<OrderDto.Search>(createSearchDto)
    const [orderList, setOrderList] = useState<OrderDto.Response[]>([])
    const [totalCount, setTotalCount] = useState<number>(1)
    const rowSize = 6
    const fetchData = async () => {
        const tempSearch = search
        tempSearch.page = page
        tempSearch.rowSize = rowSize
        if (isAdmin) {
            tempSearch.userId = userId
            console.log(userId)
        }

        const result = isAdmin
            ? await getAdminOrderListApi(tempSearch)
            : await getOrderListApi(tempSearch)
        setOrderList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <MainFrame>
            <OrderTableList orderList={orderList} />
            <PageBar
                page={page}
                totalCount={totalCount}
                setPage={setPage}
                rowSize={rowSize}
            />
        </MainFrame>
    )
}

export default UserOrderInfo
