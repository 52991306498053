import MainFrame from '@Component/layout/MainFrame'
import {CmmDto, summeryMap} from '@Types/cmm'
import styles from './index.module.scss'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {AdminOrderList} from "@Component/domains/order/OrderList";
import {ReviewView} from "@Component/domains/board/View/ReviewView";
import ReviewList from "@Component/domains/board/List/ReviewList";
import InquiryList from "@Component/domains/board/List/InquiryList";

export const OrderSummery: React.FC<{ summery?: CmmDto.Summery }> = ({
                                                                         summery,
                                                                     }) => {
    const modal = getModalInstance()

    const total = summery?.orderCounts
        ? Object.values(summery.orderCounts).reduce(
            (sum, value) => (sum || 0) + (value || 0),
            0
        ) : 0

    const handlerClick = async (value: string) => {

        if(value =='미답변 리뷰'){
            modal?.popup(<MainFrame title={'미답변 리뷰'}><ReviewList noAnswer={true} isAdmin={true}/></MainFrame>)
        }else if(value =='미답변 문의'){
            modal?.popup(<MainFrame title={'미답변 문의'}><InquiryList noAnswer={true} isAdmin={true}/></MainFrame>)
        }else{
            modal?.popup(<AdminOrderList orderState={value}/>)
        }
    }

    return (
        <MainFrame
            title={`오늘의 할일 (${total})`}
            marginZero={true}
            maxWidth={2000}
        >
            <div className={styles.todoInfo}>
                {Array.from(summeryMap?.entries()).map(([key, value]) => (
                    <div key={key} onClick={() => handlerClick(value)}>
                        {value}{' '}
                        <span>({summery?.orderCounts?.[key] || 0})</span>
                    </div>
                ))}
            </div>
        </MainFrame>
    )
}