export function getValueByLabel(
    options: { label: string; value: string }[],
    label: string
): string | undefined {
    const option = options.find((opt) => opt.label === label)
    return option?.value
}

export function getLabelByValue(
    options: { label: string; value: string }[],
    value?: string
): string | undefined {
    const option = options.find((opt) => opt.value === value)
    return option?.label
}
