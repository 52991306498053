import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {BoardDto} from "@Types/board";
import ImageTag from "@Component/common/Image/ImageTag";
import {getPlainText, privacyName} from "@Util/stringUtils";
import ReviewRating from "@Component/domains/board/ReviewRating";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {Link} from "react-router-dom";
import {ReviewView} from "@Component/domains/board/View/ReviewView";


export const ReviewGridList = (props: {
    boardList: BoardDto.Response[] | null
    isAdmin?: boolean
}): JSX.Element => {
    const boardList = props.boardList
    const [adminUrl, setAdminUrl] = useState<string>('')

    useEffect(() => {
        if (props.isAdmin) {
            setAdminUrl('/admin')
        }
    }, [props.isAdmin])

    if (!boardList || boardList?.length == 0) {
        return <div className={'empty-view'}>
            리뷰가 존재하지 않습니다.
        </div>
    }

    return (
        <div className={styles.gridReview}>
            {boardList.map((board, index) => (
                <GridItem board={board} key={index}/>
            ))}
        </div>
    )
}


const GridItem = (props: { board: BoardDto.Response }) => {
    const [viewCamera, setViewCamera] = useState<boolean>(true)
    const board = props.board
    const modal = getModalInstance()
    return <div className={styles.item}>
        <div className={styles.boardImage}
             onClick={() => {
                 modal?.popup(<ReviewView boardId={board.boardId}/>, '800px')
             }}>
            <ImageTag
                src={`${process.env
                    .BOARD_IMAGE_BASE_URL!}${
                    board.boardId
                }/0.png`}
                draggable="false"
                alt={'리뷰 메인이미지'}
                fallbackSrc={`${process.env
                    .PRODUCT_IMAGE_BASE_URL!}${
                    board.product?.productId
                }/THUMBNAIL/0.png`}
                onError={() => {
                    setViewCamera(false)
                }}
            />
            {viewCamera && <i className={'icon-camera'}></i>}
        </div>
        <div className={styles.info}>
            <p className={styles.title}>{board.title}</p>
            <p className={styles.content}>{getPlainText(board.content)}</p>
            <p className={styles.userName}>{privacyName(board?.user?.userName || '')}</p>
        </div>
        <div className={styles.productInfo}>
            <Link to={'/product/detail/' + board?.product?.productId}  className={styles.productImage}>
                <ImageTag
                    src={`${process.env
                        .PRODUCT_IMAGE_BASE_URL!}${
                        board.product?.productId
                    }/THUMBNAIL/0.png`}
                    alt={'리뷰 상품이미지'}
                />
            </Link>
            <div>
                <p className={styles.title}>{board.product?.name}</p>
                <ReviewRating
                    data={board}
                    name={'reviewRating'}
                    size={'var(--font-size-0-7)'}
                />
            </div>
        </div>
    </div>
}