// OrderRequestList.tsx
import React from 'react'
import { OrderDto } from '@Types/order'

import MainFrame from '@Component/layout/MainFrame'
import styles from '@Component/domains/order/OrderInfo/index.module.scss'
import { formatDate } from '@Util/dateUtile'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { RequestView } from '@Component/domains/board/View/RequestView'
import { AnswerEditor } from '@Component/domains/board/Edit/AnswerEditor'

interface Props {
    currentState?: string
    orderRequestList?: OrderDto.Request[]
    isAdmin?: boolean
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderRequestInfoList: React.FC<Props> = ({
    currentState,
    orderRequestList,
    isAdmin,
    setRefresh,
}) => {
    const modal = getModalInstance()

    if (orderRequestList?.filter((request) => request.board)?.length == 0) {
        return <></>
    }

    return (
        <MainFrame
            title={'요청 내역'}
            hideSiteName={true}
            className={styles.info}
        >
            {orderRequestList
                ?.filter((request) => request.board) // board가 존재하는 요청만 필터링
                .map((request, index) => (
                    <div
                        key={index} // 고유한 key로 boardId를 우선 사용
                        className={styles.requestInfoItem}
                        onClick={() => {
                            modal?.popup(
                                isAdmin ? (
                                    <AnswerEditor
                                        boardId={request.board!.boardId}
                                        currentState={
                                            index === 0
                                                ? currentState
                                                : undefined
                                        }
                                    />
                                ) : (
                                    <RequestView
                                        boardId={request.board!.boardId}
                                        orderState={index === 0
                                            ? currentState
                                            : undefined}
                                    />
                                ),
                                '900px',
                                () => {
                                    if (setRefresh) {
                                        setRefresh(true) // 새로고침 함수 호출
                                    }
                                }
                            )
                        }}
                    >
                        <h4>{request.status}</h4>
                        <p>{formatDate(request.stepDate)}</p>
                        {request?.board?.answer && <span>답변완료</span>}
                    </div>
                ))}
        </MainFrame>
    )
}

export default OrderRequestInfoList
