// OrderPaymentInfo.tsx
import React from 'react'
import { OrderDto, paymentType } from '@Types/order'
import MainFrame from '@Component/layout/MainFrame'
import styles from '@Component/domains/order/OrderInfo/index.module.scss'
import { getLabelByValue, getValueByLabel } from '@Util/codeUtil'

interface Props {
    orderDto?: OrderDto.Response
    isAdmin?: boolean
}

const OrderPaymentInfo: React.FC<Props> = ({ orderDto, isAdmin }) => {
    if (!orderDto?.paymentType) {
        return <></>
    }
    return (
        <MainFrame
            className={`${styles.infoFlex} ${styles.info}`}
            title="결제정보"
            hideSiteName={true}
        >
            <div>
                <p>결제수단</p>
                <span>
                    {getLabelByValue(paymentType, orderDto?.paymentType)}
                </span>
            </div>
            {orderDto?.paymentType === 'ACCOUNT' && (
                <>
                    <div>
                        <p>입금계좌안내</p>
                        <span>신한 110-454-160504 손영욱</span>
                    </div>
                    <div>
                        <p>송금계좌</p>
                        <span>
                            {orderDto?.accountCode} {orderDto?.accountHolder}{' '}
                            {orderDto?.accountNumber}
                        </span>
                    </div>
                </>
            )}
            <div>
                <p>결제금액</p>
                <span>{orderDto?.paymentAmount?.toLocaleString()} 원</span>
            </div>
            {orderDto?.cancelAmount != 0 && (
                <div>
                    <p>취소금액</p>
                    <span>{orderDto?.cancelAmount?.toLocaleString()} 원</span>
                </div>
            )}
        </MainFrame>
    )
}

export default OrderPaymentInfo
