import {useEffect, useState} from "react";
import {UserDto} from "@Types/user";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {getRequest, postRequest} from "@Api/Api";
import {BoardDto} from "@Types/board";
import {formatDate} from "@Util/dateUtile";

export const getUserListApi = async (searchDto: UserDto.Search) => {

    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchDto,
        }

        return await getRequest<UserDto.Page>(
            '/admin/user',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const changeUserRoleApi = async (roleUser: UserDto.Info) => {

    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200:()=>{
                modal?.alert(()=>{modal?.closeModal()},'알림','유저권한이 변경되었습니다.');
            }
        }
        const httpAlert: HttpAlert = {
            403:()=>{
                modal?.alert(()=>{},'알림','변경 권한이 없습니다.');
            }
        }


        return await postRequest<UserDto.Page>(
            '/admin/user',
            roleUser,
            httpSuccess,
            httpAlert,
        )
    } catch (e) {
        console.log(e)
    }
}

