import React, {useEffect, useState} from 'react'
import Item from '@Component/layout/ListField/Item'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import ListTemplate, {RowOption,} from '@Component/layout/ListField/ListTemplate'
import Header, {HeaderOption} from '@Component/layout/ListField/Header'
import Footer from '@Component/layout/ListField/Footer'
import {PageBar} from '@Component/common/PageBar'
import {useNavigate} from 'react-router-dom'

import {formatDate} from '@Util/dateUtile'
import {createUserSearchDto, UserDto} from '@Types/user'
import SearchField, {ActionField} from '@Component/layout/SearchField'
import {userSearch} from "@Component/domains/user/RoleBoard/searchFeild";
import {getUserListApi} from "@Api/admin/account";
import {checkUserInfo} from "@Service/user/UserService";
import {RoleEditor} from "@Component/domains/user/RoleBoard/Editor";


const RoleBoardList = () => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const [search, setSearch] = useState<UserDto.Search>(createUserSearchDto)
    const [userList, setUserList] = useState<UserDto.Info[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rowSize, setRowSize] = useState<number>(5)

    const headerOptions: HeaderOption[] = [
        {name: '이름', rowCount: 1, width: '25%'},
        {name: '전화번호', rowCount: 1, width: '30%'},
        {name: '이메일', rowCount: 1, hidden:true,  width: '30%'},
        {name: '권한', rowCount: 1,  width: '15%'},
    ]

    const rowOptions: RowOption[] =
        userList.length != 0
            ? userList.map((user, index) => ({
                rowList: [
                    <Item
                        content={user.userName}
                        colSpan={1}
                    />,
                    <Item
                        content={user.phoneNumber}
                        colSpan={1}
                    />,
                    <Item
                        content={user.email}
                        colSpan={1}
                        hidden={true}
                    />,
                    <Item
                        content={user.role}
                        colSpan={1}
                    />,
                ],
                onClick: () => {
                    modal?.popup(<RoleEditor userInfo={user}/>,'400px',()=>{fetchData()})
                },
            }))
            : [
                {
                    rowList: [
                        <Item
                            key="no-reviews"
                            content={
                                <div className={'empty-view'}>
                                    해당하는 계정이 존재하지 않습니다.
                                </div>
                            }
                            colSpan={5}
                        />,
                    ],
                },
            ]


    const actionFieldList: ActionField[] = [
        {
            label: '검색',
            onClick: () => {
                fetchData()
            },
        },
    ]


    const fetchData = async () => {
        const temp = search
        temp.page = page
        temp.rowSize = rowSize

        const result = await getUserListApi(search)
        if (page != 1 && !result?.content) {
            setPage(page - 1)
        }
        setUserList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <>
            <SearchField
                searchFieldList={userSearch()}
                actionFieldList={actionFieldList}
                data={search}
                setData={setSearch}
            />
            <ListTemplate
                header={<Header options={headerOptions}/>}
                row={rowOptions}
                footer={<Footer options={[]}/>}
            />
            <PageBar
                rowSize={rowSize}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
            />
        </>
    )
}

export default RoleBoardList
