import { SearchField } from '@Component/layout/SearchField'

export const userSearch: SearchField[][] = [
    [
        {
            type: 'date',
            label: '조회 기간', // 라벨
        },
    ],
    [
        {
            type: 'select',
            name: 'orderState', // 주문 상태 필드명
            label: '주문 상태', // 라벨
            options: [
                { value: '', label: '전체 조회' },
                { value: '입금대기', label: '입금대기' },
                { value: '결제완료', label: '결제완료' },
                { value: '준비중', label: '배송준비중' },
                { value: '배송중', label: '배송중' },
                { value: '배송완료', label: '배송완료' },
            ],
        },
        {
            type: 'text',
            name: 'orderName', // 주문명 필드명
            label: '주문명', // 라벨
            placeholder: '주문명을 입력하세요', // 플레이스홀더 추가
        },
    ],
]

export type OrderType = '주문' | '취소' | '교환' | '반품'

const getAdminOrderSearch = (orderType: OrderType): SearchField[][] => {
    const orderStatusOptions = {
        주문: [
            { value: '', label: '-전체조회-' },
            { value: '주문완료', label: '주문완료' },
            { value: '준비중', label: '준비중' },
            { value: '배송중', label: '배송중' },
            { value: '배송완료', label: '배송완료' },
        ],
        취소: [
            { value: '취소', label: '-전체조회-' },
            { value: '취소요청', label: '취소요청' },
            { value: '취소완료', label: '취소완료' },
            { value: '취소반려', label: '취소반려' },
        ],
        교환: [
            { value: '교환', label: '-전체조회-' },
            { value: '교환신청', label: '교환신청' },
            { value: '교환중', label: '교환중' },
            { value: '교환완료', label: '교환완료' },
            { value: '교환반려', label: '교환반려' },
        ],
        반품: [
            { value: '반품', label: '-전체조회-' },
            { value: '반품신청', label: '반품신청' },
            { value: '반품중', label: '반품중' },
            { value: '반품완료', label: '반품완료' },
            { value: '반품반려', label: '반품반려' },
        ],
    }

    return [
        [
            {
                type: 'select',
                name: 'orderState',
                label: '주문상태',
                options: orderStatusOptions[orderType] || [], // orderType에 맞는 옵션을 설정
            },
            {
                type: 'select',
                name: 'paymentType',
                label: '결제수단',
                options: [
                    { value: '', label: '전체' },
                    { value: 'BRC', label: '카드/간편결제' },
                    { value: 'BRA', label: '계좌이체' },
                ],
            },
        ],
        [
            {
                type: 'text',
                name: 'deliveryNumber',
                label: '배송번호',
                placeholder: '배송번호를 입력하세요',
            },
            {
                type: 'text',
                name: 'orderCode',
                label: '주문번호',
                placeholder: '주문번호를 입력하세요',
            },
        ],
        [
            {
                type: 'text',
                name: 'userName',
                label: '이름',
                placeholder: '수령인 이름을 입력하세요',
            },
            {
                type: 'text',
                name: 'userPhone',
                label: '연락처',
                placeholder: '수령인 연락처를 입력하세요',
            },
        ],
        [
            {
                type: 'date',
                label: '조회기간',
                placeholder: '날짜 선택',
            },
        ],
    ]
}

export default getAdminOrderSearch
