import { UserDto } from '@Types/user'
import { OrderDto } from '@Types/order'
import { useEffect, useState } from 'react'
import WishView from '@Component/domains/product/Wish'
import { ViewProduct } from '@Component/domains/product/ViewProduct'
import { checkUserInfo, kakaoLogin } from '@Service/user/UserService'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import styles from './index.module.scss'
import MainFrame from '@Component/layout/MainFrame'
import OrderDashBoard from '@Component/domains/order/OrderDashBoard'
import UserInfoView from '@Component/domains/user/UserInfoView'

const MypageInfo = () => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')

    useEffect(() => {
        const modal = getModalInstance()
        if (code == '409') {
            modal?.alert(
                () => {},
                '알림',
                '계정이 연동되어있습니다.',
                '이미 연동된 카카오 계정입니다.'
            )
        }
    }, [code])

    return (
        <MainFrame className={styles.accountInfo}>
            <h3>유저 정보</h3>
            <UserInfoView isMypage={true} />
            <h3>진행 현황</h3>
            <OrderDashBoard isAdmin={false} />
            <WishView />
            <br /> <br />
            <ViewProduct />
        </MainFrame>
    )
}

export default MypageInfo
