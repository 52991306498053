import React from 'react'
import ReviewList from '@Component/domains/board/List/ReviewList'
import MainFrame from '@Component/layout/MainFrame'
import RoleBoardList from "@Component/domains/user/RoleBoard/List";

const RoleBoardPage = () => {
    return (
        <MainFrame
            title={'관리자 계정관리'}
            documentTitle={'관리자 계정관리'}
            maxWidth={1100}
        >
            <RoleBoardList />
        </MainFrame>

    )
}

export default RoleBoardPage
