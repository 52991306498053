import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
// import {OrderPageBar} from "@Component/common/Index/Index";
import {createSearchDto, OrderDto} from '@Types/order'
import {PageBar} from '@Component/common/PageBar'

import MainFrame from '@Component/layout/MainFrame'
import SearchField, {ActionField} from '@Component/layout/SearchField'
import getAdminOrderSearch, {OrderType, userSearch} from './searchFeild'
import {getOrderListApi} from '@Api/user/order'
import {getAdminOrderListApi} from '@Api/admin/order'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import OrderEdit from '@Component/domains/order/OrderEdit'
import {OrderFlexList} from './ListType/flex'
import {OrderTableList} from './ListType/table'
import {excelListDownload} from "@Service/order";

export const OrderList: React.FC<{ isModal?: boolean }> = ({isModal}) => {
    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<OrderDto.Search>(createSearchDto)
    const [orderList, setOrderList] = useState<OrderDto.Response[]>([])
    const [totalCount, setTotalCount] = useState<number>(1)
    const rowSize = 6
    const fetchData = async () => {
        const tempSearch = search
        tempSearch.page = page
        tempSearch.rowSize = rowSize
        const result = await getOrderListApi(tempSearch)
        setOrderList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [page])

    const actionFieldList: ActionField[] = [
        {
            label: '검색',
            onClick: () => {
                fetchData()
            },
        },
    ]

    return (
        <MainFrame>
            {!isModal &&
                <SearchField
                    searchFieldList={userSearch}
                    data={search}
                    setData={setSearch}
                    actionFieldList={actionFieldList}
                />}
            <OrderFlexList orderList={orderList}/>
            <PageBar
                page={page}
                totalCount={totalCount}
                setPage={setPage}
                rowSize={rowSize}
            />
        </MainFrame>
    )
}

export const AdminOrderList = (props: { orderState?: string }) => {
    const modal = getModalInstance()
    const [searchParams] = useSearchParams()
    const orderType = searchParams.get('orderType')
    const isValidOrderType = (value: any): value is OrderType => {
        return ['주문', '취소', '교환', '반품'].includes(value)
    }

    const validOrderType: OrderType = isValidOrderType(orderType)
        ? orderType
        : '주문'

    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<OrderDto.Search>(
        createSearchDto(validOrderType)
    )
    const [orderList, setOrderList] = useState<OrderDto.Response[]>([])
    const [totalCount, setTotalCount] = useState<number>(1)
    const rowSize = 6

    const fetchData = async (pageReset?: boolean, orderType?: string) => {
        const tempSearch = search
        tempSearch.page = pageReset ? 1 : page
        tempSearch.rowSize = rowSize
        tempSearch.orderState =
            orderType != undefined ? orderType : tempSearch.orderState
        const result = await getAdminOrderListApi(tempSearch)
        setOrderList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    const actionFieldList: ActionField[] = [
        {
            label: '주문 등록',
            onClick: () => {
                modal?.popup(<OrderEdit/>, '800px', () => {
                    fetchData(true)
                })
            },
        },
        {
            label: '엑셀 다운로드',
            onClick: () => {
                excelListDownload(search)
            },
        },
        {
            label: '검색',
            onClick: () => {
                if (page == 1) {
                    fetchData(true)
                } else {
                    setPage(1)
                }
            },
        },
    ]

    useEffect(() => {
        fetchData()
    }, [page])

    useEffect(() => {
        let orderState
        if (props.orderState) {
            orderState = props.orderState
        } else {
            orderState = validOrderType != '주문' ? validOrderType : ''
            setSearch({
                ...search,
                orderState: orderState,
            })

            fetchData(true, orderState)
        }
    }, [validOrderType, props.orderState])

    useEffect(() => {
        fetchData(true, props.orderState)
    }, [props.orderState])
    return (
        <MainFrame>
            {!props.orderState && (
                <SearchField
                    searchFieldList={getAdminOrderSearch(validOrderType)}
                    data={search}
                    setData={setSearch}
                    actionFieldList={actionFieldList}
                />
            )}

            <OrderTableList orderList={orderList}/>

            <PageBar
                page={page}
                totalCount={totalCount}
                setPage={setPage}
                rowSize={rowSize}
            />
        </MainFrame>
    )
}
