import React from 'react'
import ReviewList from '@Component/domains/board/List/ReviewList'
import MainFrame from '@Component/layout/MainFrame'
import {TabBar} from "@Component/layout/TabBar";
import {boardPageTabOptions} from "@Page/admin/board/boardTabOption";

const AdminReviewPage = () => {
    return (
        <MainFrame  maxWidth={1000}>
            <TabBar options={boardPageTabOptions}/>
            <MainFrame
                title={'상품리뷰'}
                documentTitle={'상품리뷰'}
                maxWidth={1100}
            >
                <ReviewList isAdmin={true}/>
            </MainFrame>
        </MainFrame>
    )
}

export default AdminReviewPage
