import MainFrame from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'

import { formList } from './form'
import { saveValid } from './valid'
import Button from '@Component/common/Input/Button'
import React, { useEffect, useState } from 'react'
import { UserDto } from '@Types/user'
import { Agreement } from '@Component/domains/cmm/Document/Agreement/Agreement'
import { Privacy } from '@Component/domains/cmm/Document/Privacy/Privacy'
import CheckBox from '@Component/common/Input/CheckBox'
import { isValid } from '@Util/formUtils'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { formatTime } from '@Util/stringUtils'
import { createAuthTokenApi } from '@Api/cmm'
import FormField, { FormFieldProps } from '@Component/layout/FormField'

import {
    createAuthToken,
    preventLoggedInAccess,
} from '@Service/user/UserService'
import { userJoinApi } from '@Api/user/account'

const UserJoinPage = () => {
    const modal = getModalInstance()
    const [saveDto, setSaveDto] = useState<UserDto.Join>({})
    const [createToken, setCreateToken] = useState<boolean>(true)

    useEffect(() => {
        preventLoggedInAccess()
    }, [])

    return (
        <MainFrame
            className={styles.form}
            maxWidth={800}
            title="회원가입"
            documentTitle="회원가입"
        >
            <FormField
                formFieldList={formList}
                data={saveDto}
                setData={setSaveDto}
                validOption={saveValid}
            />
            <Agreement height="200px" />
            <CheckBox
                label="서비스이용약관 동의"
                data={saveDto}
                setData={setSaveDto}
                name="agreement"
                extraClass={styles.checkBox}
            />
            <Privacy height="200px" />
            <CheckBox
                label="개인정보처리방침 동의"
                data={saveDto}
                setData={setSaveDto}
                name="privacy"
                extraClass={styles.checkBox}
            />
            <label htmlFor="auth-token-form">
                <p>
                    인증번호 입력<span id="auth-token-message"></span>
                </p>
                <Button
                    name="인증번호받기"
                    onClick={() => {
                        createAuthToken(saveDto, createToken, setCreateToken)
                    }}
                />
            </label>
            <input
                type="text"
                placeholder="AUTH TOKEN"
                onChange={(event) => {
                    const { value } = event.target as HTMLInputElement
                    setSaveDto((prev) => ({
                        ...prev,
                        authToken: value,
                    })) // authToken 업데이트
                }}
            />
            <Button
                extraClass={styles.halfButton}
                name="회원가입"
                onClick={async () => {
                    if (await isValid(saveDto, saveValid, true)) {
                        if (createToken) {
                            modal?.alert(
                                () => {},
                                '알림',
                                '인증토큰을 발급/재발급 해주세요.'
                            )
                        } else {
                            userJoinApi(saveDto)
                        }
                    }
                }}
            />
        </MainFrame>
    )
}

export default UserJoinPage
