import {getImageList} from "@Util/imageUtils";

import {ProductDto} from "@Types/product";
import {saveBoardApi} from "@Api/user/board";
import {saveAdminBoardApi} from "@Api/admin/board";

export const saveBoard = async (saveDto: ProductDto.Save,
                                fileMap?: Map<number, File>, isAdmin?: boolean) => {

    const formData = fileMap? getImageList(fileMap) : new FormData()
    formData.append('saveDto', JSON.stringify(saveDto))
    isAdmin ? await saveAdminBoardApi(formData) : await saveBoardApi(formData)
}
