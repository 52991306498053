import {OrderDto} from '@Types/order'
import {getRequest, postRequest, putRequest} from '@Api/Api'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import {formatDate} from '@Util/dateUtile'
import {HttpAlert, HttpSuccess} from '@Types/cmm/HttpCallBack'

export const getAdminOrderListApi = async (searchQuery: OrderDto.Search) => {
    const modal = getModalInstance()
    try {
        searchQuery.startDate = formatDate(searchQuery?.startDate?.toString())
        searchQuery.endDate = formatDate(searchQuery?.endDate?.toString())
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderDto.Page>(
            '/admin/order/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getAdminOrderApi = async (orderCode?: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderDto.Response>(
            `/admin/order/${orderCode || ''}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
export const insertOrderApi = async (order: OrderDto.AdminInsert) => {
    const temp = order
    temp.paymentAmount = temp?.paymentAmount?.replaceAll(',', '')
    if (temp?.paymentType != 'ACCOUNT') {
        temp.accountCode = ''
        temp.accountNumber = ''
        temp.accountHolder = ''
    }

    try {
        await postRequest('/admin/order', order)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const updateAdminOrderApi = async (
    order: OrderDto.AdminUpdate,
    updateType: string
) => {
    const temp = order
    temp.paymentAmount = temp?.paymentAmount?.replaceAll(',', '')
    temp.cancelAmt = temp?.paymentAmount?.replaceAll(',','')
    if (temp?.paymentType != 'ACCOUNT') {
        temp.accountCode = ''
        temp.accountNumber = ''
        temp.accountHolder = ''
    }

    try {
        await putRequest('/admin/order/' + updateType, order)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const cancelPayment = async (order: OrderDto.AdminUpdate) => {
    const  temp = order
    temp.cancelAmt = order.cancelAmt?.replaceAll(',','');
    try {
        await putRequest('/admin/order/cancel/payment', temp)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
